import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PageTitle from "src/components/page-title"

import gengoImage from "src/images/case-study-images/case-study-gengo.png"

function Gengo() {
  return (
    <Layout>
      <SEO title="Gengo: Nickelled Case Studies" />
      <div class="bg-white overflow-hidden">
        <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div class="mx-auto text-base max-w-prose lg:max-w-none">
            <h2 class="text-base text-oOrange font-semibold tracking-wide uppercase">
              Case Study
            </h2>
            <h1 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Case Study: Gengo
            </h1>
          </div>
          <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="relative lg:row-start-1 lg:col-start-2">
              <svg
                class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      class="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div class="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <img
                      class="rounded-lg shadow-lg object-cover object-center"
                      src={gengoImage}
                      alt="Gengo"
                      width="1184"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div class="mt-8 lg:mt-0">
              <div class="text-base max-w-prose mx-auto lg:max-w-none">
                <p class="text-lg text-gray-500">
                  Gengo is one of the world’s leading online translation
                  companies, empowering talented people everywhere to help the
                  world communicate.
                </p>
                <p class="text-lg text-gray-500">
                  Gengo’s web-based, high quality translation services are used
                  by businesses around the world to operationalise translation
                  in a way unimaginable just a decade ago — enabling, for
                  example, the near-real-time translation of documents for an
                  important business deal or a rapid launch of an ecommerce
                  business in a new country. Its clients include global names
                  such as YouTube, Buzzfeed and AirBnB.
                </p>
              </div>
              <div class="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <h3>The challenge of onboarding</h3>
                <p>
                  To facilitate its services, Gengo manages over 21,000
                  translators around the world outputting over one million words
                  of content every day. Thanks to this network, new jobs can be
                  picked up and translated in minutes, offering Gengo’s
                  customers the speed of algorithmic translation with the
                  accuracy and quality of human translation, at a sixth of the
                  cost of a traditional translation service.
                </p>
                <p>
                  In 2016, Gengo’s Community Manager Lara Fernandez and Head of
                  Translator Operations Shoma Kimura sought a new way to ensure
                  that new members of Gengo’s translation team were up-to-speed
                  as quickly as possible after they signed up as part of the
                  team.
                </p>
                <p>
                  As translators are at the heart of Gengo’s business, Lara and
                  Shoma knew that moving them quickly towards success — their
                  ability to start doing what they love and earning their living
                  — was absolutely critical.
                </p>
                <p>
                  “We were looking for a way to guide all new translator around
                  our platform and to ensure that they got certain pieces of
                  information (especially regarding RSS) which they might miss
                  if given the passive option to simply browse through support
                  articles. While installing an RSS feed reader is optional, we
                  wanted to ensure that every new translator would receive the
                  same information encouraging them to try one,” explains Lara
                  Fernandez.
                </p>
                <p>
                  “Our primary objective was the ensure that our translators
                  made it through these steps and that we could track how well
                  they were progressing. For us, success is a translator who
                  understands the system and can dive right in on day one to get
                  some work done and start earning money with us.”
                </p>
                <h3>Building Nickelled walkthroughs</h3>
                <p>
                  After experimenting with several solutions, Lara and Shoma
                  turned to Nickelled to help onboard new translators onto their
                  systems. Working hand-in-hand with the Nickelled team, Gengo
                  created a series of simple, step-by-step guides to help their
                  new users understand and use the first screens they visited on
                  the Gengo translation interface.
                </p>
                <p>
                  “Building Nickelled guides was a simple process, which was a
                  relief,” says Lara. “We are able to build and deploy our first
                  guides to translators very quickly, as there was no
                  integration work to be done in our app, so we didn’t need to
                  wait for developers or a new release of the app.”
                </p>
                <p>
                  Gengo’s first guides acted as a virtual tour for new
                  translators, simultaneously highlighting elements of the
                  interface that they needed to understand and prompting them to
                  take actions such as installing optional software that helps
                  their workflow.
                </p>
                <p>
                  As a company serving translators around the world in multiple
                  languages, Gengo’s requirements were somewhat unique, but the
                  company was able to make heavy use of Nickelled’s localisation
                  abilities — launching guides in five different languages and
                  working with the Nickelled team to add further languages.
                </p>
                <h3>The results</h3>
                <p>
                  Gengo now offers guides to every new translator who joins the
                  team, delivering the right experience depending on the
                  person’s language and role.
                </p>
                <p>
                  “In the past few months, our percentage of new translators who
                  become active within the first 12 weeks after signing up has
                  increased, eventually reaching our goal of 60%,” says Lara.
                </p>
                <p>
                  “The percentage of new translators who have become active
                  within the first 12 weeks after signup was around 38% in
                  January 2017. For the last couple of months, it fluctuates
                  around our goal number of 60%, meaning that more translators
                  are picking up jobs within a short period after signup.”
                </p>
                <p>
                  “We're currently tracking how many of our translators are
                  indeed using an RSS feed reader, but we can definitely see a
                  correlation between our efforts and the results.”
                </p>
                <p>
                  At the start of 2017, Gengo and Nickelled began working
                  together to explore other ways that Nickelled may be used
                  within the business, including the onboarding of new clients
                  who require translations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Gengo
